/* import __COLOCATED_TEMPLATE__ from './email-verification-banner-component.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency-decorators';

export default class EmailVerificationBannerComponent extends Component {
  @service notificationsService;
  @service appService;

  @tracked isEmailResending = false;

  get app() {
    return this.appService.app;
  }

  get admin() {
    return this.app?.currentAdmin;
  }

  get emailApprovalRequiredOrPendingVerificationOrRestricted() {
    return (
      this.app?.awaiting_email_approval ||
      this.admin?.current_email_pending_verification ||
      this.app?.restricted_to_engaged_only ||
      this.app?.email_rate_limited
    );
  }

  get shouldShowBanner() {
    return (
      this.emailApprovalRequiredOrPendingVerificationOrRestricted &&
      this.app?.isNotDeveloperWorkspace
    );
  }

  @task({ drop: true })
  *resendValidationEmail() {
    try {
      yield this.admin.resendValidationEmail();
      this.notificationsService.notifyConfirmation(
        'The email has been resent, please check your inbox.',
      );
    } catch (error) {
      this.notificationsService.notifyResponseError(error, {
        default: `We couldn't resend the verification email, please try again.`,
      });
    }
  }
}
