/* import __COLOCATED_TEMPLATE__ from './configuration-pane-platform.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import moment from 'moment-timezone';

export default class ConfigurationPanePlatform extends Component {
  @service appService;
  @service('messenger-settings-service') settingsService;
  @service intl;

  MULTIBRAND_LAUNCHED_TO_NEW_APPS_DATE = '2025-01-31';

  get canShowMultiBrandInfoBanner() {
    // Don't show the banner if the workspace has ever been on the multibrand beta
    // as these apps have already configured the mobiel SDK styles correctly during the Beta
    if (this.appService.app.hasBeenOnMultibrandBeta) {
      return false;
    }

    // Don't show the banner if multibrand beta FF is not enabled (will be globally enabled soon)
    // This check is there to prevent the banner from showing until we go live to all
    if (!this.appService.app.canUseMultiBrandEmailOpenBeta) {
      return false;
    }

    // Don't show the banner if the app was created after the multibrand launch
    if (this.isAppCreatedAfterMultibrandLaunch) {
      return false;
    }

    if (!this.hasMobileBrandSettings && this.args.platform === 'mobile') {
      return false;
    }

    return true;
  }

  get hasMobileBrandSettings() {
    return (
      this.args.model.lookAndFeel.brands.filter((brand) => brand.platform === 'mobile').length > 1
    );
  }

  get isAppCreatedAfterMultibrandLaunch() {
    return moment(this.appService.app.created_at).isSameOrAfter(
      this.MULTIBRAND_LAUNCHED_TO_NEW_APPS_DATE,
    );
  }

  get bannerDescription1() {
    if (!this.hasMobileBrandSettings) {
      return this.intl.t('messenger.settings-layout.configuration-pane.banner-description-web-1', {
        htmlSafe: true,
      });
    }
    return this.intl.t(
      'messenger.settings-layout.configuration-pane.banner-description-mobile-sdk-1',
    );
  }

  get bannerDescription2() {
    return this.intl.t(
      'messenger.settings-layout.configuration-pane.banner-description-mobile-sdk-2',
    );
  }

  get canShowBannerDescription2() {
    return this.hasMobileBrandSettings;
  }
}
