/* import __COLOCATED_TEMPLATE__ from './special-notice-composer.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import type TemporaryExpectationsModel from 'embercom/models/messenger-settings/temporary-expectations';
import type Store from '@ember-data/store';
import { tracked } from '@glimmer/tracking';
import type IntlService from 'embercom/services/intl';
import { action } from '@ember/object';
import {
  BaseConfig,
  BlocksDocument,
  type ComposerPublicAPI,
} from '@intercom/embercom-prosemirror-composer';
import { htmlToTextContent } from 'embercom/lib/html-unescape';
import { next } from '@ember/runloop';
import { INLINE_CONTROL_BLOCK_TYPES } from '@intercom/embercom-prosemirror-composer/lib/config/composer-config';

export const MAX_CHARACTER_COUNT = 500;

class ComposerConfig extends BaseConfig {
  autoFocus = true;
  allowedBlocks = ['paragraph'];
  tools = [{ name: 'text-formatter' }, { name: 'anchor-editor' }];
  allowedInline = ['bold', 'italic', 'anchor'];
  allowTextAlignment = false;
  singleBlockMode = false;
  maxCharacterCount = MAX_CHARACTER_COUNT;
  hideFromTextFormatter = [INLINE_CONTROL_BLOCK_TYPES];
  placeholder = '';
}

type Locale = {
  localeId: string;
  name: string;
};

interface Args {
  locale: Locale;
  temporaryExpectations: TemporaryExpectationsModel;
}

export default class SpecialNoticeComposer extends Component<Args> {
  @service declare store: Store;
  @service declare appService: $TSFixMe;
  @service declare attributeService: $TSFixMe;
  @service declare intl: IntlService;

  @tracked blocksDoc: BlocksDocument;
  @tracked composerApi?: ComposerPublicAPI;
  @tracked remainingCharacters = MAX_CHARACTER_COUNT;

  constructor(owner: unknown, args: never) {
    super(owner, args);
    this.blocksDoc = new BlocksDocument(this.serializedBlocks);
    this.remainingCharacters = this.calculateRemainingCharacters(this.blocksDoc);
  }

  get app() {
    return this.appService.app;
  }

  get composerConfig() {
    return new ComposerConfig();
  }

  get locale() {
    return this.args.locale;
  }

  get temporaryExpectations() {
    return this.args.temporaryExpectations;
  }

  get temporaryExpectationsLocalizedMessage() {
    if (!this.temporaryExpectations.localizedMessages) {
      return null;
    }

    return this.temporaryExpectations.localizedMessages.findBy('localeId', this.locale.localeId);
  }

  get serializedBlocks() {
    return [
      {
        type: 'paragraph',
        text: this.temporaryExpectationsLocalizedMessage?.content || '',
      },
    ];
  }

  calculateRemainingCharacters(blocksDoc: BlocksDocument) {
    let blockText: string = blocksDoc.blocks
      .map(
        (block, index) =>
          (index === 0 ? '' : '\n') + (block.type === 'paragraph' ? block.text : ''),
      )
      .join('');
    let textContent = htmlToTextContent(blockText);
    return MAX_CHARACTER_COUNT - textContent.length;
  }

  @action updateBlocks(blocksDoc: BlocksDocument) {
    this.remainingCharacters = this.calculateRemainingCharacters(blocksDoc);
    this.temporaryExpectations.updateLocalizedMessage(this.locale.localeId, blocksDoc);
  }

  @action onReady(composerApi: ComposerPublicAPI) {
    this.composerApi = composerApi;

    next(this, () => {
      this.composerApi?.composer.commands.focus();
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'MessengerSettings::Content::SpecialNoticeComposer': typeof SpecialNoticeComposer;
    'messenger-settings/content/special-notice-composer': typeof SpecialNoticeComposer;
  }
}
