/* import __COLOCATED_TEMPLATE__ from './collapsible-preview.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import { action } from '@ember/object';
import { post } from 'embercom/lib/ajax';
import { isPresent } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import { pluralize } from 'ember-inflector';
import { objectTypes } from 'embercom/models/data/matching-system/matching-constants';

export default class Preview extends Component {
  @service attributeService;
  @service intercomEventService;
  @service contentEditorService;

  @tracked openSectionId;
  @tracked invalidPhoneNumbersCount;
  @tracked validPhoneNumbersCount;

  get columns() {
    let columns = [
      {
        label: 'Name',
        icon: 'people',
        valuePath: 'displayAs',
      },
    ];

    if (!this.args.hideDisplayableAttributes) {
      columns = columns.concat(
        this.attributeService.displayedUserAttributes.map((displayedAttribute) => ({
          label: displayedAttribute.attribute.name,
          icon: displayedAttribute.attribute.icon,
          valuePath: displayedAttribute.attribute.identifier,
        })),
      );
    }

    return columns;
  }

  get hasEditablePredicates() {
    return isPresent(this.args.ruleset.predicateGroup.editablePredicates);
  }

  get isEmptyState() {
    return (
      (!this.showSpinner && !this.args.ruleset.get('audiencePreview.hasAudience')) ||
      this.isTargetingVisitorsOnly
    );
  }

  get isLimitedAudiencePreview() {
    return this.args.ruleset.get('audiencePreview.limited');
  }

  get emptyStateMessageForUsers() {
    return `No ${pluralize(this.userTypeDescription)} match these rules yet`;
  }

  get emptyStateDescriptionForVisitors() {
    if (this.hasEditablePredicates) {
      return this.args.ruleset.isMemberOfSeries
        ? 'Improve the targeting by adding rules'
        : 'This will show to visitors whenever they match the audience rule';
    }
    let readableObjectName = this.args.ruleset.isMemberOfSeries
      ? 'series'
      : this.args.ruleset.rulesetLinks.firstObject.humanReadableObjectName;
    return `Once live, all visitors will be shown this ${readableObjectName}. Adding rules will improve your targeting.`;
  }

  get emptyStateMessageforVisitors() {
    if (this.args.ruleset.isMemberOfSeries) {
      if (this.hasEditablePredicates) {
        return 'Visitors who match the rules will enter this series';
      } else {
        return 'All visitors will enter this series';
      }
    } else if (this.hasEditablePredicates) {
      return `Visitors who match the rules will receive this ${this.args.ruleset.rulesetLinks.firstObject.humanReadableObjectName}`;
    } else {
      return `All visitors will receive this ${this.args.ruleset.rulesetLinks.firstObject.humanReadableObjectName}`;
    }
  }

  get isTargetingVisitorsOnly() {
    return this.args.ruleset.userType === 'visitor';
  }

  get showSpinner() {
    return this.args.ruleset.fetchAudiencePreviewTask.isRunning;
  }

  get showRemainderCount() {
    return (
      this.args.ruleset.get('audiencePreview.count') >
      this.args.ruleset.get('audiencePreview.users.length')
    );
  }

  get userTypeDescription() {
    let userTypeFromAudiencePreview =
      this.args.ruleset.get('audiencePreview.predicateGroup.userType') ||
      this.args.ruleset.get('audiencePreview.rolePredicateGroup.userType');
    let userType = userTypeFromAudiencePreview || this.args.ruleset.userType || 'user';
    if (userType === 'user') {
      return 'user';
    } else if (userType === 'lead') {
      return 'lead';
    }
    return 'person';
  }

  get rulesetHasTriggers() {
    if (!this.args.ruleset) {
      return false;
    }

    return this.args.ruleset.rulesetTriggers.length > 0;
  }

  get rulesetTriggerEventName() {
    return this.args.ruleset.rulesetTriggers.firstObject.eventName;
  }

  get showInvalidPhoneNumbersWarning() {
    if (!this.invalidPhoneNumbersCount || !this.validPhoneNumbersCount) {
      return false;
    }
    let percentageOfInvalidNumbers =
      (this.invalidPhoneNumbersCount * 100) /
      (this.invalidPhoneNumbersCount + this.validPhoneNumbersCount);
    return percentageOfInvalidNumbers >= 99;
  }

  get isSms() {
    let rulesetObjectTypes = this.args.ruleset.rulesetLinks.map((link) => link.objectType);
    return rulesetObjectTypes.includes(objectTypes.sms);
  }

  get audiencePreviewHeadingTranslationKey() {
    if (this.args.audiencePreviewHeadingTranslationKey) {
      return this.args.audiencePreviewHeadingTranslationKey;
    }

    return 'matching-system.audience-selector.collapsible-preview.preview-audience-heading';
  }

  @action
  onPreviewSectionOpen() {
    this.intercomEventService.trackAnalyticsEvent({
      object: 'audience_preview',
      action: 'clicked',
    });
  }

  @task
  *fetchInvalidPhoneNumbersCount() {
    let response = yield post('/ember/users/count.json', {
      app_id: this.args.ruleset.get('app.id'),
      predicates: [
        {
          attribute: 'role',
          comparison: 'in',
          type: 'role',
          value: ['contact_role', 'user_role'],
        },
        { attribute: 'phone_country', type: 'string', comparison: 'unknown', value: null },
      ],
    });
    this.invalidPhoneNumbersCount = response.count;
  }

  @task
  *fetchValidPhoneNumbersCount() {
    let response = yield post('/ember/users/count.json', {
      app_id: this.args.ruleset.get('app.id'),
      predicates: [
        {
          attribute: 'role',
          comparison: 'in',
          type: 'role',
          value: ['contact_role', 'user_role'],
        },
        { attribute: 'phone_country', type: 'string', comparison: 'known', value: null },
      ],
    });
    this.validPhoneNumbersCount = response.count;
  }

  constructor() {
    super(...arguments);
    this.args.ruleset.fetchAudiencePreviewTask.perform(this.args.ignoredAttributeTypesForPreview);
    if (this.isSms) {
      this.fetchInvalidPhoneNumbersCount.perform();
      this.fetchValidPhoneNumbersCount.perform();
    }
  }
}
