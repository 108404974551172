/* import __COLOCATED_TEMPLATE__ from './messages.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type MessengerSettingsService from 'embercom/services/messenger-settings-service';
import type MessengerSettingsTargetUserProvider from 'embercom/services/messenger-settings-target-user-provider';
import { action } from '@ember/object';
import type StartConversationButtonText from 'embercom/models/messenger-settings/start-conversation-button-text';

type Args = {
  model: $TSFixMe; // type of app/models/messenger-settings/all.js
  newSettingsDesign?: boolean;
};

export default class Messages extends Component<Args> {
  @service declare messengerSettingsService: MessengerSettingsService;
  @service declare messengerSettingsTargetUserProvider: MessengerSettingsTargetUserProvider;
  @service declare appService: $TSFixMe;
  get inboundConversationsDisabled() {
    if (this.messengerSettingsTargetUserProvider.isMobileOrWebUserType) {
      return !this.args.model.visibility.inboundConversationsEnabledForUsers;
    } else {
      return !this.args.model.visibility.inboundConversationsEnabledForVisitors;
    }
  }

  @action
  updateSetExpectationValue(value: StartConversationButtonText['setExpectations']) {
    this.args.model.startConversationButtonText.setExpectations = value;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'MessengerSettings::Messages': typeof Messages;
    'messenger-settings/messages': typeof Messages;
  }
}
