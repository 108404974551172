/* import __COLOCATED_TEMPLATE__ from './special-notice.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import Component from '@glimmer/component';
import type TemporaryExpectationsModel from 'embercom/models/messenger-settings/temporary-expectations';

type SimpleLocale = { localeId: string; isPermitted: boolean; name: string };

export interface Args {
  temporaryExpectations: TemporaryExpectationsModel;
  languages: {
    supportedLocales: Array<SimpleLocale>;
    availableLocales: Array<SimpleLocale>;
    defaultLocale: string;
  };
}

export default class SpecialNotice extends Component<Args> {
  get languages() {
    return this.args.languages;
  }

  get permittedLocales() {
    let defaultLocale = this.languages.supportedLocales.findBy(
      'localeId',
      this.languages.defaultLocale,
    );

    let otherPermittedLocales = this.languages.supportedLocales.filter(
      (locale) => locale.localeId !== this.languages.defaultLocale && locale.isPermitted,
    );
    return [defaultLocale, ...otherPermittedLocales].compact();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'MessengerSettings::Content::SpecialNotice': typeof SpecialNotice;
    'messenger-settings/content/special-notice': typeof SpecialNotice;
  }
}
