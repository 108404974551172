/* import __COLOCATED_TEMPLATE__ from './header.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import templateOnly from '@ember/component/template-only';

interface Signature {
  Element: HTMLElement;
  Args: {
    Named: {
      title: string;
      configuration: any;
      botOnly?: boolean;
      collapsed?: boolean;
      admin?: $TSFixMe;
      teamAvailable?: boolean;
    };
  };
}

const PreviewWebHeader = templateOnly<Signature>();

export default PreviewWebHeader;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'MessengerPreview::Web::Conversation::Header': typeof PreviewWebHeader;
  }
}
